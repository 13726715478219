.image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    border: 2px solid rgb(24, 71, 154);
    max-height: 330px;
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 20px;
}

.image-item {
    background: #fff;
    border: 1px solid #000;
}

.image-header {
    text-align: end;
}

.remove-button {
    background-color: #18479a;
    color: #fff;
    border: none;
    padding: 2px 6px;
    cursor: pointer;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

.file-name {
    margin: 0;
}

.preview-image {
    width: 100%;
    object-fit: contain;
}

.upload-section {
    position: relative;
    text-align: center;
    border: 1px dashed;
    padding: 40px;
}

.eyeIcon{
    display: flex;
    justify-content: center;
    font-size: 36px;
    margin: 10px;
}

.uploadIcon{
    font-size: 36px;
}